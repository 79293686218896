import { useEffect } from 'react';

import config from 'CONFIG';
import cookies from '#utils/cookies';
import useLocalStorage from '#hooks/useLocalStorage.ts';
import analytics from '#utils/analytics.ts';
import flows from '#utils/flows.ts';

export default function URLData() {
  const { search = '' } = import.meta.env.SSR ? {} : window.location;
  const [, setCommercialLinksData] = useLocalStorage<object>('_cl', {});
  const [, setCheckboxStatus] = useLocalStorage<boolean>('hide_checkbox', false);
  const [, setCheckoutInitialProduct] = useLocalStorage<string>('checkout_initial_product', '');

  const validateLength = (param: string, start: number, end?: number): boolean => {
    if (!param) return false;
    const regex = new RegExp(`^.{${start}${end ? `,${end}` : ''}}$`, 'g');
    return regex.test(param);
  };

  useEffect(() => {
    const {
      affId,
      offerId,
      goal,
      transactionId,
      country,
      set,
      utmMedium,
      msaid,
      coupon,
      test,
      product,
      email,
    } = flows.extractCommercialParams();

    const isNewCustomer = cookies.get('_aff_tds')?.split('|')[1] !== transactionId;
    const isAffFlow = flows.isAffFlow();
    const isSMMFlow = flows.isSMMFlow();
    const isEmailMarketingFlow = flows.isEmailMarketingFlow();
    const isSalesFlow = flows.isSalesFlow();
    const initialProduct = product && [...config.ALL_PLANS].includes(product) ? product : '';

    // if product code is wrong and test != true -> invalid link and redirect to main page
    if (product && !initialProduct) {
      analytics.event({
        category: 'Funnel',
        action: 'product_invalid',
      });
      if (!import.meta.env.SSR) window.location.assign('/');
      return;
    }
    const checkoutInitialProduct =
      {
        true: 'TRIAL',
        false: initialProduct,
      }[test] ?? '';
    const shouldHide = isSMMFlow || isEmailMarketingFlow || isSalesFlow;

    // hide checkbox on checkout for specific flow
    if (shouldHide) setCheckboxStatus(true);

    if (isSMMFlow || isEmailMarketingFlow || isSalesFlow) {
      setCheckoutInitialProduct(checkoutInitialProduct || initialProduct);
      setCommercialLinksData({
        set,
        utmMedium,
        country,
        test,
        product,
        coupon,
        msaid,
        email,
        ...(isSalesFlow && { isSalesLink: true }),
        ...((isSMMFlow || isEmailMarketingFlow) && { isMarketingLink: true }),
      });
    }

    // validate params for affiliate flow
    const isValidData =
      isNewCustomer &&
      (validateLength(affId, 5, 8) ||
        validateLength(offerId, 1, 3) ||
        validateLength(goal, 2, 3) ||
        validateLength(transactionId, 30));

    if (!isValidData || !isAffFlow) return;

    // actions for affiliate flow if params are valid
    const affIdNumber = affId.substring(3);
    const affData = `${affIdNumber}|${transactionId}|${goal}|${country}|${offerId}|${set}`;
    cookies.set('_aff_tds', affData);
    setCommercialLinksData({
      affId,
      transactionId,
      goal,
      country,
      offerId,
      set,
      utmMedium,
      test,
      product,
      isAffLink: true,
    });
    setCheckboxStatus(true);
    setCheckoutInitialProduct(checkoutInitialProduct);
  }, [search]);

  return '';
}
